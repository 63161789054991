.su-widget-form {
  fieldset {
    padding: 0;
  }

  label {
    font-weight: $su-font-regular;
  }

  .em-user-section-title {

    &.help-widget-header {

      .heading {
        font-size: 3.5rem;
        font-weight: $su-font-bold;
      }
    }
  }

  .em-help-widget-container {

    .em-help-widget-info {

      .em-basic-input,
      .em-select-input {
        margin-bottom: 0;
      }

      .em-help-widget-heading {
        font-size: inherit;

        .heading {
          @include type-d;
          font-weight: $su-font-bold;
        }
      }

      .em-help-widget-checkbox-group {
        margin-top: 3rem;
      }

      // Field label plus a marker next to it that includes help text tooltip on :hover.
      // See marker styles directly below.

      a[rel~="tooltip"],
      sup.required {
        background-color: $su-color-black;
        border-radius: 1rem;
        font-size: 1.3rem;
        height: 1.6rem;
        line-height: 1.3;
        margin-bottom: 0.25rem;
        text-decoration: none;
        width: 1.6rem;
      }

      .em-select-input {

        .picked_item_list {

          span {
            @include modular-typography(-1);
            background: $su-color-white;
            margin: 0.5rem 0.5rem 0 0;


            a {
              position: initial;

              i.fas {
                @include modular-typography(-1);
                margin-left: .25rem;
                position: initial;
                transform: none;
              }
            }
          }
        }
      }
    }

    // Make the 'Widget Embed Code' heading above the text area with the code bold.
    #x-widget-javascript {

      span {
        font-weight: $su-font-bold
      }
    }

    // This is the textarea that loads when the 'Generate Embed Code' button is pressed.
    #x-widget-javascript-code {
      background: $su-color-black;
      border: 1px solid $su-color-black;
      color: $su-color-white;
      font-size: 1.6rem;
      max-width: none;
      min-height: 400px;
      padding: 1rem;
    }
  }
}

// Right side of widget builder and digest builder pages has a box with some help text.
.em-example-widget-explanation {
  background-color: $su-color-white;

  .em-example-widget-explanation-title {
    font-size: 1.3rem;
  }
}
