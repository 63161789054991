// Styles specifically for static content pages.
// The .su-content-page class is on the <body> element.

.su-content-page {

  .su-card {

    // On the /about page there is a card with a ul.
    // Localist resets the ul to list-style-type: none;
    // We need the dots here.
    ul {
      list-style-type: disc;
    }
  }
}
