// Within the left sidebar of the event detail pages (_detail-page.scss),
// there is a component that displays future instances of the event if the
// event is reoccurring. The Localist implementation was not set up well,
// especially for a11y, so we've refactored.

// Since we're using a <button> element but don't want it to look like
// a typical button, we have to override Decanter button styles.
.su-more-dates {
  @include responsive-spacing('margin-top', -1);
}

.su-more-dates__button {
  background-color: transparent;
  color: $su-color-black;
  font-size: 1.6rem;
  font-weight: $su-font-semi-bold;
  padding: 0;
  text-align: left;
  width: 100%;

  &:focus,
  &:hover {
    background-color: transparent;
    box-shadow: none;
    color: $su-color-black;
  }

  &[aria-expanded='true'] {

    i {
      transform: rotate(45deg);
    }
  }
}

.su-more-dates__list {
  background-color: $su-color-foggy-light;
  font-size: 1.6rem;
  margin: 1rem 0 0;
  padding: 1rem;
}
