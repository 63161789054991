// Localist tabs. As seen on homepage – Trending / Upcoming
// Also within authenticated pages, additional styles in _authenticated.scss.
.tabs_component {

	.tabs_container {
		@include responsive-spacing('margin', 0);

		.tabs {
			height: 4rem;

			.tab {
				color: $su-color-black;
				font-size: 2.1rem;
				text-decoration: none;
				text-transform: uppercase;
			}
		}
	}
}
