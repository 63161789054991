// Styles for Google Maps embed.
// These display on event detail pages.

.em-section_map {
  @include responsive-spacing('margin-bottom', 1);

  .em-map_actions {

    .em-map_link {

      .em-map_label {
        margin-top: 0;

        span {
          font-size: inherit;
        }

        i {
          font-size: 1.4rem;
        }
      }

      .em-map_transport {

        i {
          font-size: 1.4rem;
          padding: 0;
          margin-right: 0;

          @include grid-media('md') {
            margin-right: .8rem;
          }
        }
      }
    }

    .em-map_container {
      border-radius: 0;
      max-height: 40rem;
    }
  }
}

.em-about_map {
  display: block;
}
