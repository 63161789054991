// Event banner – currently displayed at bottom of homepage.
.em-event_banner {
	@include box-shadow(shallow, 1px solid color(card-border));
	border-radius: 0;
	padding: 8rem;

	p {

		&.em-banner_text {
			@include modular-typography(0);
			font-weight: $su-font-regular;
		}
	}

	.em-event_button {
		@include button-secondary;
		border-radius: 0;

		i.fas {
			font-size: 1.6rem;
		}
	}

	.em-event_banner-overlay {
		background-color: $su-color-cardinal-red-dark;
		background-image: none;
		border-radius: 0;
	}
}
