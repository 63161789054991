// Styles for the site's main footer.
.site-footer {
  background-color: $su-color-white;

  .flex-container {
    @include centered-column;
    @include responsive-spacing("padding", 3 null);

    h2 {
      font-size: .9em;
      color: $su-color-cardinal-red;
      padding: 0;
      margin-bottom: 1.3rem;
    }

    @include grid-media-max("sm") {

    > div {
        @include responsive-spacing("padding", 2 null 2 null);
        border-bottom: 1px solid $su-color-black;

        > ul {
          margin-bottom: 0;
        }

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
