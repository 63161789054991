// Modals appear in different cases across the platform.
// 1. When an authenticated user updates some info a small modal appears to show error or success.
// 2. When authenticated and viewing another user's profile page, the Send Message button opens a modal. (fancybox)
// 3. When not authenticated, the Log in button opens a modal. (fancybox)

// When a user updates things in Account Settings and saves
// a notification box appears. These are style for that box.
.em-flash-message-container {
	max-width: 128rem;

	p {
		margin-bottom: 0;
	}
}

.em-flash-message {

	.em-flash-message-close {
		align-self: flex-start;

		button {
			background: transparent;
			color: $su-color-black;
			padding: 0;

			&:active,
			&:focus {
				box-shadow: none;
			}
		}
	}

	.em-flash-message-container & {

		&.em-private-profile {
			min-width: 300px;
		}
	}
}

// Send Message modal styles
.em-send-message-modal {

	.em-send-message-body {

		textarea {
			border: 1px solid $su-color-black;
			border-radius: 0;
			font-size: inherit;
			height: 13rem;
			max-width: none;

			&::placeholder {
				font-size: inherit;
			}

			&:focus {
				border-color: $su-color-digital-blue;
				box-shadow: 0 0 0 1px $su-color-digital-blue;
			}
		}
	}
}
