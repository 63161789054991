// <button> element and links styled as buttons with .em-button class.

// Localist buttons are picking up some button styles from Decanter
// that we don't want. Overriding those here.
button,
input {

	&.em-share-button {
		background-color: transparent;
		line-height: 0;
		padding: inherit;

		&:focus,
		&:hover {
			box-shadow: none;
		}
	}

	&.em-dropdown-item {
		background-color: $su-color-white;
		color: $su-color-black;

		&:focus,
		&:hover {
			box-shadow: none;
		}
	}

	&.em-button {

		&.em-primary {
			@include button-primary;
		}

		&.em-secondary {
			@include button-secondary;
		}

		&.em-big {

			i.fas {
				@include modular-typography(0);
				margin-left: 1rem;
			}
		}

		// Filter button, specifically.
		.em-filter & {

			&.em-secondary.em-ghost {
				@include button-primary;
				min-width: 5.6rem;
				padding: 1.8rem;

				&:focus,
				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	&.em-select-fallback {

		// <button> in .em-filter-dropdown that shows a list of event type checkboxes to select
		.em-select-input & {
			@include modular-typography-base;
			background-color: initial;
			background: url("#{$su-image-path}/arrow-down.png");
			background-image: url("#{$su-image-path}/arrow-down.svg");
			background-position: right 1.3rem center;
			background-repeat: no-repeat;
			background-size: 1.3rem;
			border: 1px solid $su-color-black;
			color: $su-color-black;
			cursor: auto;
			height: 4.8rem;
			text-align: left;

			&:hover {
				background-color: initial;
				text-decoration: none;
			}

			&:focus {
				box-shadow: none;
			}
		}
	}
}

.em-button {

	&.em-primary {
		@include button-primary;
	}

	&.em-secondary {

		&.em-ghost {
			@include button-secondary;
			padding: 1.8rem;

		}
	}

	&.em-big {
		font-size: inherit;
	}

	&:not(.em-big) {
		@include button-secondary;
	}

	i {
		&.fa,
		&.far,
		&.fas,
		&.fab {
			font-size: 1.4rem;
		}
	}
}
