// Styles for event feedback form that appears above past event when
// feedback is solicited for the event.

.em-event-feedback {

  &.em-event-feedback--status-pending {

    .em-event-feedback--reminder {
      @include responsive-spacing('padding-top', -1);
      text-align: center;
    }
  }

  .em-event-feedback--container {

    h1,
    > p {
      text-align: center;
    }
  }

  .em-event-feedback--form {
    max-width: 62rem;

    .em-event-feedback--responserow {

      &.em-event-feedback--response-yesno,
      &.em-event-feedback--response-rating,
      &.em-event-feedback--highlight-row {

        input {
          border: 0;
        }

        label {
          @include responsive-spacing('margin-top', -1);
          background-color: $su-color-white;
          border-radius: 0;
        }
      }

      &.em-event-feedback--response-rating-header {

        span {
          font-size: 1.4rem;
        }
      }
    }

    .em-star-rating {

      + label {
        @include responsive-spacing('margin-top', -1);
      }
    }
  }
}

.em-star-rating-container {

  &:not(:checked) {

    > input {
      border: 0;
    }

    > label {

      &:before {
        color: $su-color-white;
      }
    }
  }
}
