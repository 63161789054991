// Styles for custom fields we've added in Localist.
// Custom fields can be added to events, places, users, and group/departments.

// General element selectors.
.su-custom-field__label {
  font-weight: $su-font-bold;
}

// Events fields.
// Restricted to: and Banner Text fields are combined in to an .su-alerts wrapper.
.su-alerts {
  @include responsive-spacing("padding", 1 null null);

  .su-custom-field {
    @include responsive-spacing('margin-bottom', 0);

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.su-custom-field--banner,
.su-custom-field--restricted {
  @include box-shadow(shallow);
  @include responsive-spacing('padding', 0);

  .su-custom-field__text {
    margin-bottom: 0;
  }
}

.su-custom-field--banner {
  background-color: $su-color-white;
  color: $su-color-digital-red;;
}

.su-custom-field--restricted {
  background-color: $su-color-digital-red;
  color: $su-color-white;
}
