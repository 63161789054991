// Styles for the login modal.
// We've moved the SUNet button to the top of the form.

.em-login-view {
  margin: 5rem auto;

  .em-default-header {
    // This is an <h1>, which has padding-top applied in _base.scss
    // we don't want that here.
    padding-top: 0;
  }
}

.em-email-signup,
.em-signup-container {

  .em-login_methods {
    // Add some padding to SUNet button so it is more prominent.
    .em-button {
      font-size: 2rem;
      margin-bottom: 1.5rem;
      padding: 2rem 1rem;
    }
  }

  .em-login-form {
    // The .su-button--secondary class was added to the markup in Localist.
    // The <input> element has a height of 4.8rem declared in Decanter.
    // We want the input button to be smaller so we're making the height auto.
    input.su-button--secondary {
      height: auto;
    }
  }
}
