// Info groups are used in the sidebar card of event detail pages as well as
// the page headers of group/department and place pages. They consist of a
// relevant Font Awesome icon paired with a piece of information such as
// a phone number, website, address, etc.

.su-info-group {
  @include responsive-spacing('padding-bottom', 1);
  align-items: baseline;
  display: flex;

	&:last-child {
		padding-bottom: 0;
	}

	&.em-actions {
		padding-bottom: 0;
	}

	&.su-info-group--white {

		a {
			color: $su-color-white;

			&:not(.em-disabled) {
				&:focus,
				&:hover {
					color: rgba($su-color-white, 0.8);
				}
			}
		}
	}
}

.su-info-group__icon {
	color: $su-color-white;
	width: 1.5em;
	text-align: center;
	margin-right: 1rem;

	&.fa-map-marker-alt,
	&.fa-tv,
	&.fa-video,
	&.fa-desktop {
		color: $su-color-poppy;

		.su-info-group--white & {
			color: $su-color-white;
		}
	}

	&.fa-calendar {
		color: $su-color-cardinal-red;
	}

	&.fa-users {
		color: $su-color-lagunita;
	}

	&.fa-link {
		color: $su-color-cool-grey;

		.su-info-group--white & {
			color: $su-color-white;
		}
	}

	&.fa-ticket-alt {
		color: $su-color-digital-blue;
	}

  &.fa-phone {
    color: $su-color-plum;

    .su-info-group--white & {
			color: $su-color-white;
		}
  }

  &.fa-clock {
    color: $su-color-spirited-dark;

    .su-info-group--white & {
			color: $su-color-white;
		}
  }

  &.fa-car {
    color: $su-color-digital-red;

    .su-info-group--white & {
			color: $su-color-white;
		}
  }

	&.fa-star {
    color: $su-color-illuminating-dark;

    .su-info-group--white & {
			color: $su-color-white;
		}
  }

	&.fa-envelope {
    color: $su-color-foggy-dark;

    .su-info-group--white & {
			color: $su-color-white;
		}
  }

	&.fa-facebook-f {
    color: $su-color-digital-blue;

    .su-info-group--white & {
			color: $su-color-white;
		}
  }

	&.fa-twitter {
    color: $su-color-digital-blue;

    .su-info-group--white & {
			color: $su-color-white;
		}
  }

	&.fa-wheelchair {
		color: #003da5
	}

	.su-info-group--white & {
		color: $su-color-white;
	}
}

.su-info-group__text {
	width: 100%;
}

.su-info-group__address {
	font-size: 1.6rem;
}

.su-info-group__social-links {
	flex: 1 0 auto;
}

.su-info-group__heading {
	@include responsive-spacing('padding-bottom', 0);
}
