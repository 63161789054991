// Styles for virtual event streaming info.
// These display on event detail pages.

.em-section_stream {

  .em-stream_actions {

    .em-stream_toggle {

      .em-stream_label {
        margin-top: 0;

        span {
          font-size: inherit;
        }

        i {
          font-size: 1.4rem;
        }
      }

      .em-stream_links {

        i {
          font-size: 1.4rem;
          padding: 0;
          margin-right: 0;

          @include grid-media('md') {
            margin-right: .8rem;
          }
        }
      }
    }

    .em-stream_information {
      text-align: left;

      h3 {
        @include responsive-spacing('margin-top', 0);
      }
    }
  }
}
