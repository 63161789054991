// The info card is used at the top of place and group/organization pages.
// It displays a category, title, info-group list (see _info-group.scss), and
// image if one is available. Wider screens are horizontal, mobile is vertical.

.su-card--info {
  display: flex;
  flex-direction: column;
  margin-bottom: 3.6rem;
  margin-top: -160px;

  @include grid-media-min('md') {
    flex-direction: row;
  }

  h1 {
    margin: 5px 0 10px 0;
    padding: 0;
  }

  p {
    margin-bottom: 0;
  }

  .em-header-card_image,
  .em-header-card_text {
    flex: 1 0 50%;
  }

  .em-header-card_text {
    @include responsive-spacing('padding', 1);
  }

  .em-header-card_image {

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
      display: block;
    }
  }

  .em-share_dropdown {
    height: auto;
    margin: 0;
    width: auto;

    .em-share-button {
      color: $su-color-digital-blue;
      display: inline;
      position: relative;
      right: 0;
      top: 0;
      transform: none;
    }
  }

  .su-card__category {
    color: $su-color-black;
    font-weight: $su-font-regular;
    margin-bottom: 0;
    text-decoration: none;
    text-transform: uppercase;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  .su-info-group {
    @include responsive-spacing('padding-bottom', 0);
  }
}