// Fancybox styles.
// Fancybox is a modal library used by Localist.
#fancybox-wrap {

	#fancybox-outer {
		border-radius: 0;
    box-shadow: 0px 0px 10px $su-color-black;

		#fancybox-close {
      color: $su-color-white;
			cursor: pointer;
      text-decoration: none;

      &::before {
        font-size: 1.4rem;
      }
    }
	}
}

// Lightbox elements.
//
// "You're not going yet!" modal for tickets/registration when clicking "Save".
.em-ticket-lightbox-modal {

	label {
		font-size: inherit;
    margin: 0;
	}

  .em-ticket-lightbox-header {
    color: $su-color-white;
    background: $su-color-cardinal-red;
    border-radius: 0;
  }

  .em-ticket-lightbox-body {
    color: $su-color-black;
    background: $su-color-white;
  }

  .em-ticket-lightbox-footer {
    background: $su-color-white;
    border-radius: 0;
    box-shadow: none;
  }
}

// When viewing a freind's profile page and clicking Send Message button.
.em-send-message-modal {

	label {
		font-size: inherit;
    margin: 0;
	}

  .em-send-message-header {
    color: $su-color-white;
    background: $su-color-spirited-dark;
    border-radius: 0;
  }

  .em-send-message-body {
    color: $su-color-black;
    background: $su-color-white;
  }

  .em-send-message-footer {
    background: $su-color-white;
    border-radius: 0;
    box-shadow: none;
  }
}

// When clicking Save on an event detail page that has recurring dates.
.em-recurrent-event-going-modal {

  label {
		font-size: inherit;
    margin: 0;
	}

  .em-event-going-header {
    color: $su-color-white;
    background: $su-color-spirited-dark;
    border-radius: 0;
  }

  .em-event-going-body {
    color: $su-color-black;
    background: $su-color-white;
  }

  .em-event-going-footer {
    background: $su-color-white;
    border-radius: 0;
    box-shadow: 0 -0.5rem 1rem rgba($su-color-black, 0.1);
  }
}
