// Replicating Decanter _card.scss styles for Loaclist Emphasis class.
// Event listing pages use the card styles and often provide buttons to switch
// the grid layout of the events.

.em-card {
	@include box-shadow(shallow, 1px solid color(card-border));
  display: block;
  background-color: color(bg);

	.em-card_tag {
		text-transform: uppercase;
		color: $su-color-cardinal-red-dark;
		margin-bottom: 0;
	}

	.em-card_tag,
	.em-card_event-date {
		font-weight: $su-font-semi-bold;
	}

	// Image.
	.em-card_image {
		height: 20rem;

		// For 2-up grid.
		.em-card-group.em-card-group--medium & {
			height: 20rem;
		}

		img {
			object-position: center;
			transition: transform 0.2s ease-in;
		}

		.img_card {
			border-radius: 0;
			transition: transform 0.3s ease-in-out;
		}

		.em-card_overlay {
			background: linear-gradient(to top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
			background-color: transparent;
		}

		@include grid-media-only('xs') {
      display: none;
    }
	}

	// Container for text elements.
	.em-card_text {
		@include responsive-spacing('padding', 2);
    @include sans;
		opacity: 1;

    p {
      font-size: 0.9em;
    }

		/// Headline.
		.em-card_title {
			@include responsive-spacing('margin', null null -1);
			@include type-c;
			line-height: 1.2;

			> a {
				color: $su-color-chocolate;
				font-weight: $su-font-bold;
				transition: color 0.3s ease-out;

				&:hover,
				&:focus {
					color: color(brand--bright);
					text-decoration: underline;
				}
			}
		}

		.em-card_share {
			font-size: 0.9em;
			height: auto;
			top: 16.5rem;
		}

		.em-card_event-text {
			color: $su-color-clay;
			opacity: 1;

			i {
				width: 1em;
			}

			a,
			span {
				margin-left: 1rem;
			}

			a {
				font-weight: $su-font-regular;
			}
		}
	}

	.em-list_tags {
		position: absolute;

		.em-card_tag {
			background-color: $su-color-palo-alto;
			border-radius: 0;
			display: inline-block;
			font-size: 1rem;
			margin: 0 0.7rem 0.7rem 0;
			text-decoration: none;

			&.em-new-tag {
				background-color: $su-color-spirited-dark;
			}
		}

		@include grid-media-only('xs') {
			position: static;
		}
	}

	// Styles for wide/horizontal cards.
	// The card display is controlled by selectors on the parent container.
	.em-card-group.em-card-group--big & {
		@include modular-spacing("padding", 0 );
		@include display-grid;
		@include grid-column-gap(calculate-modular-spacing(1));
		grid-template-columns: auto 1fr;
		height: auto;

		.em-card_image {
			border-radius: 0;
			height: 150px;
			width: 150px;

			@include grid-media('lg') {
				height: 200px;
				width: 200px;
			}

			.em-card_overlay {
				background: none;
				background-color: transparent;
			}
		}

		.em-card_text {
			padding: 0;
			width: auto;

			p {
				font-size: 0.9em;
			}

			.em-card_share {
				justify-content: flex-end;

				a {
					&:first-child {
						margin: 0;

						&.em-card_share-link--tix {
							margin-right: auto;
						}
					}
				}
			}

			.em-share_dropdown {

				.em-link {
					font-size: 1rem;
				}

				[class^="em-list-buttons"] {
					transform: translateX(30%);
				}
			}

			.em-price-tag {
				margin-top: 0;
			}
		}

		.em-list_tags {
			top: 2rem;
			left: 2.5rem;
		}
	}

	// .em-card :hover & :focus effects
	&:focus,
	&:hover,
	&:focus-within {

		// No scaling effect on the Spotlight and Slider Channel
		// page components – see _channel-components.scss.
		&:not(.em-card--carousel) {
			.em-card_image img {
				transform: scale(1.03);
			}
		}
	}
}

// Social media sharing links that appear when the icon is clicked.
// Icon appears when card is hovered.
.em-share_dropdown {

	[class^="em-list-buttons"] {

		.em-buttons-item {

			.em-buttons-link {
				border-radius: 0;
				height: 4rem;
				width: 4rem;
			}
		}
	}
}

// An "I'm interested" button appears on cards when hovered.
.em-interested-tag {
	border-radius: 0;
	font-size: 1.4rem;
	padding: 0.5rem 0.8rem;
	text-decoration: none;
}

// Card grids for different layouts...
.em-card-group.em-card-group--big {
	grid-template-columns: 1fr;
}
