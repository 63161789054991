// Styles for Channel page components.

// Many components have a border around them and padding,
// we're removing that.
.em-add-event-block-component,
.em-default-list-component,
.em-description-component,
.em-hero-image-component,
.em-rss-component,
.em-platform-nav-component,
.em-social-link-component,
.em-website-link-component {
  border: none;
  border-radius: 0;
  padding: 0;
}

// Department List component.
// <ul> has this id.
#x-department {
  margin: 0;
  padding: 0;
}

// Class applied to component titles/names.
// This is also used in the tertiary nav
// (three dots on homepage) in _filter.scss.
.em-content_label {
  font-size: 3rem;
  letter-spacing: 0;
  text-transform: none;
}

// Cards that display with Featured Groups component.
.em-card_about {

  &.em-card_about--white {
    border-color: color(card-border);
    border-radius: 0;

    img {
      border-radius: 0;
    }
  }
}

// Map component.
.em-map-component {

  .map-component-map {
    height: 32rem;
    border-radius: 0;
  }
}

// Event Spotlight and Featured Slider components
// share some styles for the layout.
.em-event-spotlight-component,
.em-featured_carousel {
  .em-card--carousel {

    .em-card_image {
      flex: 1 0 50%;
      height: 100%;
    }

    .em-card_text {
      @include responsive-spacing('padding', 1);
      flex: 1 0 50%;
      position: initial;

      .em-card_title {
        @include serif;
        @include responsive-spacing('margin-bottom', 1);
      }

      p {
        font-size: inherit;
        margin: 0;
      }

      .em-card_share {
        justify-content: flex-end;
        margin-top: 0;

        a:first-child {
          margin: 0;
        }
      }
    }
  }

  img {
    transform: none;
  }
}

// Event Spotlight component specifically.
.em-event-spotlight-component {
  .em-card--carousel {
    display: block;

    @include grid-media('md') {
      display: flex;
      align-items: center;
      height: 50rem;
    }
  }
}

// Featured Slider component specifically.
.featured_section_component {

  .em-featured_carousel {
    border-radius: 0;
    height: 50rem;

    @include grid-media('sm') {
      height: 58rem;
    }

    @include grid-media('md') {
      height: 50rem;
    }

    .em-featured_item_wrapper {

      .em-slider {
        min-height: 40rem;

        @include grid-media('sm') {
          height: 50rem;
        }
      }

      .em-item {
        height: 40rem;

        @include grid-media('sm') {
          height: 50rem;
        }

        &.em-card {
          box-shadow: none;
          display: flex;
          align-items: start;

          @include grid-media('sm') {
            align-items: center;
          }
        }
      }

      // Play/Pause and slide indicator controls.
      .em-carousel--slideposition {
        background-color: transparent;
        border-radius: 0;

        @include grid-media('md') {
          background-color: rgba($su-color-white, 0.75);
        }

        ul {
          margin: 0;
          padding: 0;

          > li {
            margin-bottom: 0;

            &:not(:last-child) {
              margin-right: 1rem;
            }
          }
        }

        // Play/Pause icon.
        .em-carousel--toggle {
          font-size: 2rem;
        }

        // Pipe between button and indicators.
        .em-carousel--separator {
          border-left: .125rem $su-color-black solid;
          border-right: none;
        }

        // Circle slide indicators.
        .indicator {
          height: 2rem;
          width: 2rem;
        }
      }
    }
  }
}

.em-header-component {

  p {
    @include type-d;
  }
}

.em-hero-image-component {

  img {
    margin-bottom: 1em;
    width: 100%;
  }

  .em-hero-image-description {
    @include type-d;
  }
}

// Spacing for those that didn't have any or had too little.
.em-about_organization,
.em-about_speakers,
.html_component {
  margin-bottom: 2.4rem;
}
