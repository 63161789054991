// Localist has classes on many form elements.
// Many elements are nested within containers with classes.

// Form elements with classes.
.em-input_label {
	@include modular-typography-base;
	font-weight: $su-font-regular;
	letter-spacing: initial;
	text-transform: none;
}

.em-basic-input {
	border-radius: 0;

	input,
	textarea {
		border-radius: 0;
		font-size: inherit;
		margin: 0;
		max-width: none;

		&:focus {
			border-color: $su-color-digital-blue;
			box-shadow: 0 0 0 1px $su-color-digital-blue;
		}

		&::placeholder {
			color: $su-color-black;
			opacity: 1;
		}
	}
}

.em-select-input {
	background-color: transparent;
	border: 0;
	border-radius: 0;

	select {
		border: 1px solid $su-color-black;
		font-size: inherit;
		margin: 0;
		max-width: none;

		&:focus {
			border-color: $su-color-digital-blue;
			box-shadow: 0 0 0 1px $su-color-digital-blue;
		}
	}

	&.calendar {
		border: 1px solid $su-color-black;
	}

	&.em-wider {

		select {
			border: 1px solid $su-color-black;
			font-size: inherit;

			&:focus {
				border-color: $su-color-digital-blue;
			}
		}
	}

	// Localist dropdown arrows are in the markup.
	// Hiding in favor of Decanter arrows, background-image in CSS.
	i.fas.fa-sort-down {
		display: none;
	}
}

// fieldset – wraps checkboxes in filter dropdown
.em-multi-select-menuitems {
	padding: 0;
}

.em-checkbox {
	align-items: flex-start;
	margin-top: 0;

	input[type="checkbox"] {
		border: 3px solid $su-color-digital-blue;
		height: 2.4rem;
		width: 2.4rem;

		&:focus {
			box-shadow: rgba($su-color-white, 1) 0px 0px 0px 2px, rgba($su-color-focus-blue, 1) 0px 0px 0px 4px, rgba($su-color-black, 0) 0px 0px 0px 0px;
		}

		&:hover {
			filter: brightness(100%);
		}

		&:checked {
			background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
			background-position: center;
			background-repeat: no-repeat;
			background-size: 100% 100%;
			border-color: transparent;

			&:after {
				display: none;
			}

			&:focus {
				background-color: $su-color-focus-blue;
			}
		}

		.em-edit-notifications & {
			flex: 0 0 2.4rem;
		}
	}

	span {
		font-size: inherit;
	}

	.em-dropdown-container & {
		align-items: center;
	}
}

// "When" field in filter dropdown
.daterangepicker {

	.ranges {

		ul {
			margin: 0;
			padding: 0;

			li {
				font-size: inherit;
				margin: 0;
			}
		}
	}

	.calendar-table {
		tr {
			td,
			th,
			.month {
				@include modular-typography-base;
			}
		}
	}

	.em-dropdown-item {
		font-size: inherit;
	}
}

.em-dropdown-container {

	.em-dropdown-item {
		font-size: inherit;
	}

	.ranges {

		ul {

			li {
				font-size: inherit;
			}
		}
	}
}
