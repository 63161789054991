.su-digest-form {

  fieldset {
    padding: 0;
  }

  input {
    margin: 0;
  }

  label {
    font-weight: $su-font-regular;
  }

  .em-user-section-title {

    &.digest-header {

      .heading {
        font-size: 3.5rem;
        font-weight: $su-font-bold;
      }
    }
  }

  .em-digest-container {

    .em-digest-info {

      .em-basic-input,
      .em-select-input {
        margin-bottom: 0;
      }

      .em-digest-checkbox-group {
        margin-top: 3rem;
      }

      a[rel~="tooltip"],
      sup.required {
        background-color: $su-color-black;
        border-radius: 1rem;
        font-size: 1.3rem;
        height: 1.6rem;
        line-height: 1.3;
        margin-bottom: 0.25rem;
        text-decoration: none;
        width: 1.6rem;
      }

      .em-digest-heading {
        font-size: inherit;

        .heading {
          @include type-d;
          font-weight: $su-font-bold;
        }
      }

      .em-select-input {

        .picked_item_list {

          span {
            @include modular-typography(-1);
            background: $su-color-white;
            margin: 0.5rem 0.5rem 0 0;


            a {
              position: initial;

              i.fas {
                @include modular-typography(-1);
                margin-left: .25rem;
                position: initial;
                transform: none;
              }
            }
          }
        }
      }
    }
  }
}
