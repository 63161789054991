@charset "UTF-8";

.su-main-nav--mobile-search {
  .su-site-search {
    display: none; //not displayed on desktop
  }

  .su-main-nav__toggle[aria-expanded="true"] {
    @include grid-media-max('xs') {
      + .su-main-nav__menu-lv1 {
        + .su-site-search {
          @include padding(2rem);
          display: block;
          width: 100%;

          .su-site-search__submit {
            top: 3.1rem;
            right: 3.1rem;
          }
        }
      }
    }

    + .su-main-nav__menu-lv1 {
      + .su-site-search {
        @include grid-media-only('md') {
          width: 24em;
        }
      }
    }
  }
}
