// Generally, these are styles for authenticated user pages of Localist.
// Some style may apply elsewhere.

// .em-header .em-navbar-container contains the user profile menus for desktop & mobile
.em-header {

	.em-navbar-container {

		.em-desktop-menu-icons {

			.em-user-avatar-desktop {
				height: 4.8rem;
				width: 4.8rem;

				.em-dot-notification {
					height: 1.2rem;
					width: 1.2rem;
				}
			}

			.em-desktop-user-menu {

				.em-desktop-profile-menu {
					@include responsive-spacing('padding', -1);
					left: -1rem;
					top: 5.6rem;

					a {
						@include responsive-spacing('padding', -2);
						font-size: 1.4rem;
					}

					&.em-open-menu {
						width: auto;
					}

					.em-dot-notification-menu {
						border-radius: 1rem;
					}
				}
			}
		}
	}

	// mobile
	.em-main-menu {
		z-index: 5;

		p {
			font-size: inherit;
			margin-bottom: 0;
		}

		.em-main-menu-container {

			.em-main-menu-container-item {
				@include responsive-spacing('padding', 1 null);

				p:not(:first-child) {
					@include responsive-spacing('padding-bottom', -2);
				}

				&.em-submit-event {
					max-width: none;
				}

				.em-sub-header {
					@include responsive-spacing('padding-bottom', -2);
					font-size: inherit;
					letter-spacing: 0;
					opacity: 1;
					text-transform: none;
				}

				.em-messages-container {

					a {
						font-size: inherit;
						font-weight: 600;
					}
				}
			}
		}
	}
}

// Subnav. This appears when logged in, right below the site header.
.em-subnav-background {
	background-color: $su-color-black;

	.em-subnav-link {
		color: $su-color-white;
		font-weight: $su-font-regular;
		text-decoration: none;

		// New message dot.
		.em-dot-notification {
			height: .6rem;
			width: .6rem;
		}
	}
}

// User Account Settings page sidebar widgets.
.em-user-section {
	@include responsive-spacing('margin', 3 null 6 null);

	.em-user-section-row {

		&.em-home_section {

			.em-view_profile {
				width: auto;
			}
		}
	}

	.em-home_sections_item {
		border-radius: 0;

		.box_left {
			height: 4.8rem;

			img {
				height: 4.8rem;
				width: 4.8rem;
			}
		}

		p {
			margin-bottom: 0;
		}

		&.em-home_sections_item--white {
			@include box-shadow(shallow, 1px solid color(card-border));
		}
	}

	.em-profile_info {

		.em-profile_info-delivery {
			@include box-shadow(shallow, 1px solid color(card-border));
			background-color: $su-color-white;

			.em-button {

				&.em-primary {
					width: auto;
				}
			}
		}

		.em-profile_info-complete {

			.em-home_lead {
				max-width: none;
			}

			.em-grid-for-style-achievement-rows {

				.item {

					&.item_ignored {

						a {
							font-size: inherit;
						}
					}
				}
			}
		}
	}

	.em-home_lead {
		font-size: inherit;
	}

	.em-user-section-tabs {

		.em-user-section-tab {
			font-size: 2.1rem;
			text-decoration: none;

			&.em-active {
				border-bottom-color: $su-color-black;
			}
		}
	}

	.em-edit-user {

		.em-edit-user-avatar {
			@include box-shadow(shallow, 1px solid color(card-border));
			background-color: $su-color-white;

			.em-avatar_photo {
				height: 11rem;
				width: 11rem;
			}

			label {
				margin-top: 0;
			}
		}

		.em-edit-user-social-connections {

			.em-social_title {
				font-size: 1.3rem;
				letter-spacing: 0;
				text-transform: none;
			}
		}
	}

	.em-tip {

		.fas {
			font-size: 1.4rem;
		}
	}

	.em-section-card {
		border-radius: 0;
	}

	.request_item {

		span {

			&.dateright {
				font-size: 1.4rem;
			}
		}
	}
}

.em-bulletins-container {

	.em-bulletins-info {

		.em-bulletins-heading {

			.heading {
				font-size: 2.1rem;
				margin-top: 0;
			}
		}
	}

	.em-bulletins-explanation {

		.em-bulletins-explanation-title {
			font-size: 1.3rem;
			letter-spacing: 0;
			text-transform: none;
		}
	}
}

// Progress bar. Appears on user dashboard Home page when logged in.
.em-progress_wrapper,
.em-progress {
	border-radius: .4rem;
	height: .8rem;
}

// Styles for Places you follow, which is under the Places tab within the Following page of the user Dashboard
.em-user-section-content {

	.items {

		.item {
			@include box-shadow(shallow, 1px solid color(card-border));

			img {
				height: 4.8rem;
				width: 4.8rem;
			}

			.item_name {
				font-size: inherit;
			}
		}
	}
}

// User profile page.
.em-profile {
	@include box-shadow(shallow, 1px solid color(card-border));
	margin-top: 0;

	.em-user_info {

		a.em-user_photo,
		img {
			height: 7.2rem;
			width: 7.2rem;
		}
	}

	.icon_item {

		a {
			height: 4.8rem;
			width: 4.8rem;
		}

		img {
			height: 4.8rem;
			width: 4.8rem;
		}
	}
}

// Recent Activity section of user profile page.
.em-activity-photo-container {

	.em-activity-photo-user-container {

		.em-activity-photo-user-profile {

			a {
				height: 4.8rem;
				width: 4.8rem;
			}

			&.em-profile-activity {

				img {
					height: 4.8rem;
					width: 4.8rem;
				}
			}

			.em-activity-photo-user-data {

				.em-activity-photo-user-name {
					font-size: 1.4rem;
				}

				.em-comment-sub-info {

					.em-activity-photo-date {
						font-size: 1.4rem;
						margin-bottom: 0;
					}
				}
			}
		}

		.em-activity-photo-user-delete {

			span {
				font-size: 1.4rem;
			}
		}
	}
}

// Messages tab page of user Dashboard.
//
// Left side, conversation list.
.em-conversation-container {

	.em-conversation-list {

		.active_entry {
			position: relative;

			&::before {
				border-radius: 0;
				height: 94%;
				width: 0.4rem;
				margin: .25rem;
			}
		}

		.em-message-group-container {
			background-color: $su-color-white;

			.em-today-message-separator {

				p {
					font-size: 1.2rem;
					margin-bottom: 0;
				}
			}
		}

		.em-conversation-nocontent-container,
		.em-messages-nocontent {
			background-color: $su-color-white;
		}
	}

	.em-conversation-item {
		@include responsive-spacing('padding', -2);

		.em-conversation-user-data {

			.em-conversation-item-avatar {

				img {
					width: 4.8rem;
				}
			}

			i {
				font-size: 1.4rem;
			}
		}

		.em-conversation-item-action {

			i {
				font-size: 1.4rem;
			}
		}
	}
}

// Right side, messages box.
.em-message-group-container {
	@include box-shadow(shallow, 1px solid color(card-border));

	.em-message-group-user-data {

		img {
			height: 3.6rem;
			width: 3.6rem;
		}

		p {
			font-size: 1.4rem;
			margin-bottom: 0;
		}
	}

	.em-new-message-form {

		.em-to-container {

			p {

				&:first-child {
					font-size: inherit;
					margin-bottom: 0;
				}
			}
		}

		.em-new-message-conversation-sender-data {

			img {
				height: 3.6rem;
				width: 3.6rem;
			}

			p {
				font-size: inherit;
				margin-bottom: 0;
			}
		}

		.em-new-message-conversation-textarea {
			border: 1px solid $su-color-black;
			border-radius: 0;
			font-size: inherit;
			height: 13rem;
			max-width: none;

			&::placeholder {
				font-size: inherit;
			}

			&:focus {
				border-color: $su-color-digital-blue;
				box-shadow: 0 0 0 1px $su-color-digital-blue;
			}
		}
	}
}

// These styles apply to the "Friends" list of the user profile page.
.list_icons {
	margin: 0;
	padding: 0;

	li {
		margin-bottom: 0;
	}

	a,
	img {
		height: 4.8rem;
		width: 4.8rem;
	}
}
