// The detail banner is a full-width component that is displayed
// at the top of event, group/department, and place pages.
// This file contains shared styles for all page types.
// Event detail page styles in _event-detail.scss
// Group/department page styles in _group-detail.scss
// Place page styles in _place-detail.scss

.su-detail-banner,
.su-detail-banner--empty {
  color: $su-color-white;
  @include responsive-spacing("padding", 2 null);

  h1 {
    margin: 5px 0 10px 0;
    padding: 0;
  }

  // Social links on group/departemnt and place pages.
  .em-about_social {
    color: $su-color-white;
  }

  // Follow button on group/departemnt and place pages.
  .em-link.em-follow_group {
    color: $su-color-white;
  }
  // Applied to the "Follow..." link after it is clicked and
  // before the follow request is approved.
  .em-link.em-disabled {
    color: rgba($su-color-white, 0.5);
  }

  // Chocolate background on event pages.
  .su-event-detail & {
    background-color: $su-color-chocolate;
  }

  // Plum background on group/department pages.
  .su-group-detail & {
    background-color: $su-color-plum;
  }

  // Lagunita to Palo Verde gradient background on group/department pages.
  .su-group-detail & {
    background: linear-gradient(to right, $su-color-plum, $su-color-digital-red);
    min-height: 20.5rem;
  }

  // Lagunita to Palo Verde gradient background on group/department pages.
  .su-place-detail & {
    background: linear-gradient(to right, $su-color-lagunita-dark, $su-color-palo-verde);
    min-height: 20.5rem;
  }
}

.su-detail-banner__category {
  color: $su-color-white;
  font-weight: $su-font-regular;
  margin-bottom: 0;
  text-decoration: none;
  text-transform: uppercase;

  &:focus,
  &:hover {
    color:rgba($su-color-white, 0.8);
    text-decoration: underline;
  }
}
