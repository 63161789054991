// Site header styles.
// Includes Stanford brand bar, logo, and (Localist's) search form.
// Breadcrumbs are included since they are right below.

// .su-brand-bar__container {
//   @include display-grid;
//   @include responsive-grid-columns($su-responsive-columns-default);
//   align-items: center;

//   @include grid-media-only('xs') {
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//   }
// }

// $su-responsive-columns-twelve: (
//   $su-breakpoint-xs: 1fr,
//   $su-breakpoint-sm: 1fr 1fr 1fr,
//   $su-breakpoint-md: 1fr 1fr 1fr,
//   $su-breakpoint-lg: 1fr 1fr 1fr 1fr 1fr,
//   $su-breakpoint-xl: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr,
//   $su-breakpoint-2xl: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr
// );

#masthead {
  @include centered-column;
  align-items: center;
}

.su-masthead-grid {
  display: grid;
  grid-template-areas:
    'user'
    'logo'
    'search';
  padding-bottom: 1.5rem;

  .su-masthead-grid__logo {
    @include responsive-spacing('margin-bottom', 0);
    grid-area: logo;
  }

  .su-masthead-grid__search {
    grid-area: search;
  }

  .su-masthead-grid__user-nav {
    grid-area: user;
  }

  @include grid-media('md') {
    grid-template-areas:
      'user user user user'
      'logo logo search search';
    padding-bottom: 4.8rem;

    .su-masthead-grid__logo {
      margin-bottom: 0;
    }
  }
}

.su-user-actions {
  @include grid-justify-self(end);

  &.em-header {
    @include responsive-spacing('margin', -1 null);
    background-color: transparent;
    box-shadow: none;

    .em-navbar-container {
      flex-wrap: wrap;
      justify-content: flex-end;
      padding: 0;

      .em-desktop-icon-list {

        a:first-child {
          display: block;
        }
      }
    }

    .su-navbar-user-name {
      color: $su-color-black;
      font-size: 1.4rem;
      font-weight: $su-font-regular;
      margin-right: 1rem;
    }

    .em-desktop-menu-icons {
      justify-content: flex-end;
      min-width: 150px;

      .em-user-avatar-desktop {
        color: $su-color-black;
        display: flex;
        align-items: center;
        height: auto;
        width: auto;
        text-decoration: none;

        img {
          width: 4.5rem;
          height: 4.5rem;
        }
      }

      .em-desktop-user-menu {

        .em-angle-down-icon {
          color: $su-color-black;
          margin: 0 1rem 0 .5rem;
        }

        .em-desktop-profile-menu {
          // background: linear-gradient(rgba(110,0,0,1), rgba($su-color-cardinal-red-dark, 1) 15%);
          background-color: $su-color-black;
          border: none;
          left: 0;
          right: 0;
          top: 4.7rem;
          z-index: 6;

          a {
            color: $su-color-white;
            position: relative;

            &:focus,
            &:hover {
              font-weight: $su-font-semi-bold;
              text-decoration: none;
            }
          }
        }
      }
    }

    .em-mobile-menu-icons {

      a {
        color: $su-color-black;

        &:focus,
        &:hover {
          text-decoration: none;
        }
      }

      .fa-user {
        margin-right: .5rem;
      }

      .em-user-avatar {
        display: flex;
        align-items: center;
        width: auto;
        text-decoration: none;

        img {
          max-width: 3rem;
        }

        .fas {
          margin-left: .5rem;
        }
      }
    }
  }

  .su-login,
  .su-signup,
  .su-help {
    font-size: 1.6rem;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  .su-login,
  .su-help {
    border-right: 1px solid;
    margin-right: 1rem;
    padding-right: 1rem;

    .fas {
      margin-right: 0.5rem;
    }
  }

  .su-signup {

    .fas {
      margin-left: .5rem;
    }
  }
}

.su-submit-event {
  border-left: 1px solid $su-color-white;
  padding-left: 1rem;
  font-size: 1.6rem;
  color: $su-color-white;

  &:focus,
  &:hover {
    color: $su-color-white;
    text-decoration: none;
  }

  .fas {
    margin-left: .5rem;
  }
}

.su-events-site-header {
  @include box-shadow(shallow, 0);
	background-color: $su-color-white;
  // box-shadow: 0px 5px 5px $su-color-stone-light;
}

#search-bar-input {
	appearance: none;
	border: $su-input-border-width solid $su-color-driftwood;
	border-radius: 3rem;
	box-sizing: border-box;
	color: $su-color-black;

	// standardize on firefox
	display: block;
	font-size: $su-base-font-size;
	height: 4rem;
	line-height: $su-input-line-height;
	max-width: 100%;
	outline: none;
	width: 100%;
  // Localist overrides
	background-color: $su-color-white;
	margin: 0;
	padding: 0.6rem 2rem 0.6rem 3.6rem;

  &:focus {
		box-shadow: $su-focus-shadow;
  }
}

.su-site-search {

	.em-search-bar {
		background-color: none;
		border-radius: 3rem;

    // The icon is targeted with these selectors in Localists stylesheet.
    // We need to override the color and opacity.
		.em-basic-input i.fas {
			color: $su-color-cardinal-red;
      opacity: 1;
		}

    .em-search-bar-form {
      z-index: 5;
    }

		.em-sub-menu {
      @include box-shadow(shallow, 1px solid color(card-border));
      border: 0;
      color: white;
      padding-top: 5rem;
			top: 1.8rem;

			a {
        font-size: inherit;
			}
		}

    // Overriding the placeholder text's opacity set in Localist's stylesheet for a11y.
    input {
      &::placeholder {
        opacity: 1;
      }
    }
	}
}

#featured-banner {
  @include responsive-spacing("padding", 2 null);
  @include font-smoothing;
  position: relative;
  background-color: $su-color-chocolate;
  background-size: cover;

  @include grid-media-max('sm') {
    min-height: 40vw;
  }

  @include grid-media-between('md', 'lg') {
    min-height: 30vw;
  }

  // &::after {
  //   background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, transparent 70%);
  //   content: "";
  //   height: 100%;
  //   width: 100%;
  //   display: block;
  //   position: absolute;
  //   top: 0;
  //   z-index: 0;
  // }

  #featured-banner-title {
    color: $su-color-white;

    p {
      font-weight: $su-font-regular;
      font-size: 1em;
      line-height: 1.2;
      margin-bottom: 0;
      text-shadow: rgba(0,0,0,0.6) 0 0 6px;
    }

    .title {
      @include serif;
      @include responsive-spacing("margin", null null 0);
      font-size: 1.8em;
      font-weight: $su-font-bold;
      text-transform: none;
    }

    @include grid-media-only('lg') {

      p {
        font-size:1.2em;
      }

      .title{
        font-size:2em;
      }
    }

    @include grid-media-only('xl'){

      p {
        font-size:1.4em;
      }

      .title{
        font-size:2.8em;
      }
    }

    @include grid-media-only('2xl') {

      p {
        font-size:1.4em;
      }

      .title{
        font-size:2.8em;
      }
    }
  }

  .static-datepicker {
    margin-bottom: 0;
  }

  .container {
    @include centered-column;
    @include flex-container;
    position: relative;
    align-items: flex-end;
    padding-top: 0;
    z-index: 1;

    #responsive-calendar {
      display: none;
    }

    @include grid-media-max('lg') {
      @include margin(20% null null);
    }

    @include grid-media-only('2xl') {

      #featured-banner-title {
        @include flex-column($columns: 9, $grid-columns: 12);
      }

      #responsive-calendar {
        display: block;
        width: 100%;
        @include flex-column($columns: 3, $grid-columns: 12);
      }
    }

    @include grid-media-only('xl') {

      #featured-banner-title {
        @include flex-column($columns: 8, $grid-columns: 12);
      }

      #responsive-calendar {
        display: block;
        width: 100%;
        @include flex-column($columns: 4, $grid-columns: 12);
      }
    }
  }
}

// Welcome banner. This is used during the launch period to welcome new
// users to the site.
.su-alert{

  .centered-container {
    align-items: flex-start;

    // We take the padding of .centered-container on event detail pages
    // because of the unique layout. Adding it back here, specifically to
    // the .su-alert banner only.
    .su-event-detail & {
      padding-bottom: 1em;
      padding-top: 1em;
    }
  }
}

// Breadcrumbs.
.em-breadcrumbs {

	ul {
		margin: 0;
		padding: 0;

		li {
			font-size: 1.6rem;
			margin: 0;
		}
	}
}
