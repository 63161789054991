// Styles applied to HTML elements as well as general element selectors.

// Localist sets letter-spacing on the html element. We don't want that.
html {
	letter-spacing: 0;
}

// Localist adds letter spacing and uppercase to the <label> element
label {
	font-size: inherit;
	letter-spacing: 0;
	max-width: none;
	text-transform: none;
}

// Decanter puts margin-bottom: 7.6rem on all <table> elements.
// Also, min-width: 100%
table {
	margin: 0;
	min-width: 0;
}

h1, h2 {
  margin-bottom: 2.7rem;
}

dd {
  margin: 0 0 3rem 4rem;
}

p,
.em-add_event .edit_sidebar,
.em-add_event .edit_sidebar p {
	@include modular-typography-base;
}

// Link styles.
.em-link {
	font-size: inherit;
  font-weight: $su-font-semi-bold;

	i.fa,
	i.far,
	i.fas,
	i.fab {
		font-size: 1.4rem;
	}
}

// .em-content_about is the wrapper around the WYSIWYG text from
// the "Description" field.
.em-content_about {

	.em-about_description {
		font-size: inherit;

		// Emphasis theme uses "circle", Stanford uses "disc".
		ul li {
			list-style-type: disc;
		}

		// Bigger and Biggest styles are available in the WYSIWYG.
		p.desc_bigger {
			@include modular-typography(1);
		}

		p.desc_biggest {
			@include modular-typography(2);
		}
	}
}

// Font Awesome icons.
// font-family: 'FontAwesomev5' is from Localist
.fa,
.fab,
.fal,
.far,
.fas {
	font-family: 'FontAwesomev5';
}

.em-price-tag {
	font-size: 1.4rem;
	line-height: inherit;
}

.su-hide {
	display: none;
}

.su-show {
	display:block;
}

.su-visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

// When help icons – i or ? – in forms are hovered a tooltip appears.
// This selector styles that display.
.tooltip {
	@include modular-typography(-1);
	font-weight: $su-font-regular;
	line-height: 1.2;
}

.em-recent-activity-container {
	background-color: $su-color-white;
}

// Follow... link turns gray when follow request is pending.
// Remove the underline so it doesn't look like a link.
.em-link.em-disabled {
	text-decoration: none;
}

// This contains an <h4> that displays on the /calendar page above the filter navigation.
// I can't find anywhere in the templates to just remove the markup, so display:none for now.
.box_title.box_header {
	display: none;
}

// This is a section title that separates groups of search/listing results.
// Most notably, it labels the sections on an initial search results page with
// Events, Places, Departments, Groups, People.
.em-subsection-title {
	font-size: inherit;
}
