// Styles specifically for the bookmarking app.
// The app is on a different URL but uses the same stylesheet.

.su-bookmark-page {

  .su-masthead-grid {
    padding-top: 4.8rem;
  }

  #main-content {
    padding: 2em 0 8em;
  }
}
