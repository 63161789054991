// Styles applied specifically to detail pages and their elements.
// The Events Page Shell is the template we've modified in order to
// follow the previous site's layout.

// Localist header card styles
.em-header-type {
  padding: 0;

	.em-header-card {
		border-radius: 0;
		max-height: none;
    padding: 0;


		&.em-event {
			.em-header-card_text {

				.em-header-card_share {
					bottom: 0;
					height: auto;
					margin-left: auto;
					position: relative;
					right: 0;
					width: auto;
				}
			}
		}

		.em-header-card_text {
      @include responsive-spacing('padding', 2);

			&::before {
				display: none;
			}

			p {
				margin: 0;
			}
		}

		.em-header-card_image {
			border-radius: 0;
      margin-top: 0;
			max-height: none;
      width: 100%;

			img {
				border-radius: 0;
			}

      .img_card {
        margin: 0;
      }
		}
	}

	&.em-header-type--event {

		.em-share_dropdown {

			.em-share-button {
				display: inline;
				position: relative;
				right: 0;
				top: 0;
				transform: none;
			}
		}
	}
}

.em-content_about {
	@include responsive-spacing('margin-bottom', 1);
  margin-top: 0;

  .em-about_location {

    .em-about_location__address,
    .em-about_location__website,
    .em-about_location__hashtag {
      font-size: 1.4rem;
    }
  }
}

.em-about_follow {
	// Localist theme has weird padding applied, so we need to zero it out
	// before applying our rules for consistent spacing.
	padding: 0;
	@include responsive-spacing('margin-bottom', 0);
	@include responsive-spacing('padding-top', 0);
	background-color: transparent;
	// Localist theme has weird margins applied, so we need to zero them out too.
	margin-left: 0;
	margin-right: 0;

  .em-followers {
    font-size: 1.4rem;
    margin-bottom: 0;
  }
}

.em-event-meta-data-component,
.em-event-editor-tools-component {
  border-radius: 0;
}

.em-category-tag {
  background-color: $su-color-palo-alto;
  border-radius: 0;
  display: inline-block;
  font-size: 1.3rem;
  letter-spacing: 1px;
  line-height: 1.23;
  margin: 0 0.7rem 0.7rem 0;
  padding: 0.56rem 0.8rem;
  text-decoration: none;

	.su-event-tag-list & {
		background-color: $su-color-white;
		box-shadow: inset 0 0 0 1px #e3e3e3;
		color: $su-color-cool-grey;

		&:hover,
		&:focus {
			background-color: $su-color-palo-alto-dark;
			box-shadow: none;
			color: $su-color-white;
		}
	}
}

.em-list_dates__toggle {

  .su-card & {
    margin-bottom: 0;
  }
}

.em-date {

  .su-card & {
    margin: 0;
  }
}

.su-event-sponsor-list {
	display: inline-block;
	margin: 0;
	padding: 0;
}

.su-event-sponsor-list__item {
	display: inline-block;
	margin-right: .35rem;
	margin-bottom: 0;

	&::after {
		content: ','
	}

	&:last-child {
		margin-right: 0;

		&::after {
			content: '';
		}
	}

	&.su-event-sponsor-list__item--last {
		margin-right: 0;
	}
}

.su-event-sponsor-list__link {
	color: $su-color-white;
	font-weight: $su-font-regular;

	&:focus,
	&:hover {
		color: rgba($su-color-white, 0.8);
		text-decoration: none;
	}
}

// Styles from original app.
.su-event-detail {

  .content-wrapper {
		padding: 0;

		&.em-breadcrumbs {
			@include centered-column;
			margin: 1rem auto;
		}
  }

	header {
		margin-bottom: 0;
	}

	.su-event-container {
		@include centered-column;
		@include flex-container;
	}

	.su-card {
		background-color: #fff;
    display: block;
		margin-bottom: 20px;

		img {
			width: 100%;
			max-width: 100%;
		}
	}

	a.su-button--secondary {
		box-shadow: inset 0 0 0 1px #e3e3e3;
		font-weight: normal;
		font-size: .9em;
		color: $su-color-black;
		@include responsive-spacing("margin", null -1 0 null);

		&:hover,
		&:focus {
			box-shadow: inset 0 0 0 2px $su-color-chocolate;
		}
	}

	hr.divider {
		margin: 0 0 1.8rem;
		border: 1px solid #e3e3e3;
	}

  .su-event-detail__main {

		h2 {
			margin-bottom: 0;
		}

		> div {
			&:not(:first-child) {
				border-top: 2px solid $su-color-cool-grey-15;
			}

			// .em-content_about is often :first-child, except when .su-alerts is present
			// with values in the Restricted to: or Banner text fields. We want to make
			// sure .em-content_about never has a border-top.
			&.em-content_about {
				border-top: 0;
			}
		}
  }

  @include grid-media-only('2xl') {

		#event-title > div {
			@include flex-column($columns: 8, $gutter: $su-gutter-2xl);
			@include flex-grid-push($size: 4, $columns: 12);
			padding-left: 16px;
		}

		.su-event-detail__sidebar {
	    @include flex-column($columns: 4, $gutter: $su-gutter-2xl);
		}

		.su-event-detail__main {
			@include flex-column($columns: 8, $gutter: $su-gutter-2xl);
		}
  }

	@include grid-media-only('xl') {

		#event-title > div {
			@include flex-column($columns: 8, $gutter: $su-gutter-xl);
			@include flex-grid-push($size: 4, $columns: 12);
			padding-left: 16px;
		}

		.su-event-detail__sidebar {
			@include flex-column($columns: 4, $gutter: $su-gutter-xl);
		}

		.su-event-detail__main {
			@include flex-column($columns: 8, $gutter: $su-gutter-xl);
		}
	}

	@include grid-media-only('lg') {

		#event-title > div {
			@include flex-column($columns: 8, $gutter: $su-gutter-lg);
			@include flex-grid-push($size: 4, $columns: 12);
			padding-left: 13.33333px;
		}

		.su-event-detail__sidebar {
			@include flex-column($columns: 4, $gutter: $su-gutter-lg);
		}

		.su-event-detail__main {
	    @include flex-column($columns: 8, $gutter: $su-gutter-lg);
		}
	}

	@include grid-media-only('md') {
		#event-title > div {
			@include flex-column($columns: 8, $gutter: $su-gutter-md);
			@include flex-grid-push($size: 4, $columns: 12);
			padding-left: 7px;
		}

		.su-event-detail__sidebar {
			@include flex-column($columns: 4, $gutter: $su-gutter-md);
		}

		.su-event-detail__main {
			@include flex-column($columns: 8, $gutter: $su-gutter-md);
		}
	}

  @include grid-media-max($name: 'sm') {
		#su-event-wrapper,
		#su-event-sidebar {
			width: 100%;
			max-width: 100%;
		}

		.su-event-detail__main {
			@include responsive-spacing("margin", null  1);
		}

		.su-card {
			width: 100%;
			margin-top: 0;

			img {
				display:block;
				width: 100%;
			}
		}
  }
}

.su-add-to-calendar {
	@include responsive-spacing('margin-top', -1)
}

.su-add-to-calendar__label {
	font-size: 1.6rem;
	font-weight: $su-font-semi-bold;
}

.su-add-to-calendar__list {
	margin: 0;
	padding: 0;
}

.su-add-to-calendar__item {
	display: inline-block;
	margin: 0;

	i {
		width: auto;
		margin-right: 1rem;
	}
}

.su-event-tag-list {
	padding: 1.8rem 0 1.1rem;
}

// Appears for authenticated users with event administrator access.
.em-event-editor-tools-component {
	@include responsive-spacing('margin-bottom', 2);
	border: 0; // Zero out the full border coming from Localist's theme.
	padding: 0;

	.em-editor_tools {
		@include responsive-spacing('margin-top', -1)
	}
}
