@charset "UTF-8";

// Decanter v7 has different :focus styles we're applying here.
// This color is used for fields with focus.
$su-color-focus-blue: #2563eb !default;

@import
"vendors/index"
, "layout/layout"
, "components/main-nav/index"
// The styles below were added for Localist – either to enhance or override.
// Classes prefixed with .em- reference the Localist "Emphasis" theme.
// Classes prefixed with .su- are Stanford-specific.
, "components/localist/localist"
;

// Styles for the "under construction" banner at the top of the page during dev.
.em-info_bar {
	background: $su-color-illuminating;

	p {
		color: $su-color-black;
		margin-bottom: 0;
	}
}

// Everthing below are pre-Localist styles.
// TODO: Clean up and remove unused.
textarea {
	height: 16rem;
}

select {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: $su-color-white;
	background: url("#{$su-image-path}/arrow-down.png");
	background-image: url("#{$su-image-path}/arrow-down.svg");
	background-position: right 1.3rem center;
	background-repeat: no-repeat;
	background-size: 1.3rem;
}

legend {
	@include type-b;
	font-weight: $su-font-bold;
}

fieldset {
	border: 0;
}

.su-fieldset-inputs {

	label {
		margin-top: 0;
	}
}

body {
	background-color: $su-color-foggy-light;
}



// Start events overrides for .list-page and .content-page
// .homepage, // Localist class
.su-list-page,
.su-content-page {

  h1 {
		text-transform: capitalize;
  }

  @include grid-media('md') {

		.column-reverse {
			display: flex;
			flex-direction: column;
		}
  }

  .event-category {
		text-transform: uppercase;
		color: $su-color-dark-red;
		font-weight: 600;
		font-size: .9em;
		margin-bottom: 0;
  }

  .su-card {
		background-color: #fff;
		margin-bottom: 20px;

		img {
			max-width:  200px;
			width:  200px;

			@include grid-media-max('lg') {
				max-width: 150px;
				width: 150px;
			}
		}

		.su-card__contents {

			> div:not(.su-card__button),
			> p:last-of-type {
				@include responsive-spacing('padding', null null -1);
				@include margin(null null 0);
			}
		}

		h3 {
			@include sans;
			@include margin(null null 0);
			@include responsive-spacing('padding', null null -1);
			@include type-c;
		}

		.event-location {
			font-weight: 400;
		}
  }

  .su-card--horizontal {
		@include responsive-spacing("padding", 0 );
		grid-template-columns: auto 1fr;
  }

  .event-date, .event-location {
		color: $su-color-clay;
		font-size: .9em;

		span {
			padding-left: 10px;
		}

		i {
			width: 1em;
			opacity: .5;
		}
  }

  .event-date {
		font-weight: 600;
		margin-bottom: 10px;
  }

  #featured-banner {
		@include responsive-spacing("padding", 2 null);
		@include font-smoothing;
		position: relative;
		background-color: $su-color-chocolate;
		background-size: cover;

		@include grid-media-max('sm') {
			min-height: 40vw;
		}

		@include grid-media-between('md', 'lg') {
			min-height: 30vw;
		}

		// &::after {
		// 	background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, transparent 70%);
		// 	content: "";
		// 	height: 100%;
		// 	width: 100%;
		// 	display: block;
		// 	position: absolute;
		// 	top: 0;
		// 	z-index: 0;
		// }

		#featured-banner-title {
			color: $su-color-white;

			p {
				font-weight: $su-font-regular;
				font-size: 1em;
				line-height: 1.2;
				margin-bottom: 0;
				text-shadow: rgba(0,0,0,0.6) 0 0 6px;
			}

			p.title {
				@include slab;
				font-size: 1.8em;
				font-weight: $su-font-bold;
				@include responsive-spacing("margin", null null 0);
			}

			@include grid-media-only('lg') {

				p {
					font-size:1.2em;
				}

				p.title{
					font-size:2em;
				}
			}

			@include grid-media-only('xl'){

				p {
					font-size:1.4em;
				}

				p.title{
					font-size:2.8em;
				}
			}

			@include grid-media-only('2xl') {

				p {
					font-size:1.4em;
				}

				p.title{
					font-size:2.8em;
				}
			}
		}

		.static-datepicker {
			margin-bottom: 0;
		}

		.content-wrapper {
			@include centered-column;
			@include flex-container;
			position: relative;
			align-items: flex-end;
			z-index: 1;

			#responsive-calendar {
				display: none;
			}

			@include grid-media-max('lg') {
				@include margin(20% null null);
			}

			@include grid-media-only('2xl') {

				#featured-banner-title {
					@include flex-column($columns: 9, $grid-columns: 12);
				}

				#responsive-calendar {
					display: block;
					width: 100%;
					min-height: 345px;
					@include flex-column($columns: 3, $grid-columns: 12);
				}
			}

			@include grid-media-only('xl') {

				#featured-banner-title {
					@include flex-column($columns: 8, $grid-columns: 12);
				}

				#responsive-calendar {
					display: block;
					width: 100%;
					@include flex-column($columns: 4, $grid-columns: 12);
				}
			}
		}
  }

  #main-content {
		@include centered-column;
		@include responsive-spacing("margin", null null 4);
  }

  #page-banner {
		@include responsive-spacing("margin", 0 null);
  }

  #feed-links, #page-title {
		margin: 0;
		padding: 0;

		@include grid-media-max('sm'){
			width: 100%;
		}
  }

	#feed-links {

		@include grid-media-max('sm') {
	    padding-top: 2rem;
		}

		a {
			@include responsive-spacing("padding",-1);
			background-color: rgba(77,79,83,0.15);
			color: $su-color-chocolate;
			font-size: 1.8rem;
			width: auto;

			@include grid-media($breakpoint:'xs'){
				@include responsive-spacing("margin", null 0 0 null);
			}
		}

		a#ical-icon {

			@include grid-media-min('lg'){
				margin-right: 0;
			}
		}

		a#rss-icon:hover {
			background-color: $su-color-poppy;
			color: $su-color-white;
		}

		a#ical-icon:hover {
			background-color: $su-color-cardinal-red;
			color: $su-color-white;
		}
  }

	.paging-navigation {
		text-align: center;
		@include responsive-spacing("margin", 3 null);

		a,
		span{
			@include responsive-spacing("margin", null -1);
			@include responsive-spacing("padding", -1 0);
		}

		.prev,
		.next {
			background-color: $su-color-dark-red;
			color: $su-color-white;
			text-decoration: none;
		}

		.current {
			background-color: $su-color-clay;
			color: $su-color-white;
		}
  }
} // End styles for .list-page and .content-page
