html {
  padding: 0;
  margin: 0;
}

body {
  padding: 0;
  margin: 0;

}

h1,h2 {
  @include responsive-spacing("padding", 1 null null);
  @include serif;
}

h1 {
  font-size: 3.5rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2rem;
}

p {
  line-height: 1.5;
}

p.lead {
  font-size: 2.2rem;
  font-weight: 300;
}

dl {

  dt {
    font-weight: bold;
  }
}

ul {
  padding-inline-start: 2.5em;
}

.su-link, a{
  color: $su-color-bright-blue;

  &:focus,
  &:hover {
    color: $su-color-blue;
    text-decoration: none;
  }
}

.datepicker {
  margin: 0;
  padding: 2px;
  background-color: #fff;
}

.datepicker-modal {
  @include box-shadow($depth: 'medium', $stroke: none);
  border-top: 5px solid $su-color-chocolate;
  margin-right: 1em;
  position: absolute;
  z-index: 2222;

  @include grid-media-max('sm'){
    right: 0;
    margin-right: 0;
    width: 100%;
  }
}
