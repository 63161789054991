@charset "UTF-8";

#su-main-nav {
	background-color: $su-color-white;
	padding: 0.5rem 0 1.5rem;
	width: 100%;

	@include grid-media('md'){
		padding: 0;
	}

  i[aria-hidden="true"] {
		display: inline;

		@include grid-media-max('sm') {
			font-size: 1.6em;
		}

		@include grid-media-max('lg') {
			display: block;
			margin-bottom: 10px;
			width: 100%;
		}
  }
}

.su-main-nav__container {
	@include centered-column;
}

.su-main-nav {
	@include font-smoothing;
	@include sans;
	display: block;
	position: relative;
	z-index: 1;

	.su-main-nav__label {
		color: $su-color-white;
	}

	//top level menu styles
	ul {
		@include margin(0 auto);
		display: grid;
		grid-gap: 1em;
		grid-template-areas: "a b c" "d e f";
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: auto auto;
		width: 100%;
		list-style: none;
		justify-content: center;
		padding: 0;

		@include grid-media-between('md','lg') {
			grid-template-areas: ". a b c d e f .";
			grid-template-columns: auto 1fr 1fr 1fr 1fr 1fr 1fr auto;
			grid-template-rows: auto;
		}

		@include grid-media('xl'){
			grid-template-areas: "a b c d e f";
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
			grid-template-rows: auto;
		}

		// Immediate <li>s of parent <ul>
		> li {

			&:nth-child(1) {
				grid-area: a;
			}

			&:nth-child(2) {
				grid-area: b;
			}

			&:nth-child(3) {
				grid-area: c;
			}

			&:nth-child(4) {
				grid-area: d;
			}

			&:nth-child(5) {
				grid-area: e;
			}

			&:nth-child(6) {
				grid-area: f;
			}

			// Immediate <a>s witihin immediate <li>s of parent <ul>
			> a {
				@include margin(0);
				@include responsive-spacing('padding', -2);
				border-bottom: 2px solid $su-color-white;
				color: $su-color-black;
				font-size: 1.8rem;
				text-align: center;
				box-shadow: none;
				font-weight: 400;
				height: 100%;
				width: 100%;
				text-decoration: none;

				@include grid-media-max('lg') {
					font-size: 15px;
					padding: 1em 0;
				}

				&:active,
				&:focus,
				&:hover {
					border-bottom: 2px solid;
				}

				&[aria-expanded="true"]::before {
					visibility: visible;
					transform: scaleX(1);
					background-color: $su-color-black;
				}

				// Custom color for each icons used.
				.fa-star {
					color: $su-color-illuminating-dark;
				}

				.fa-calendar-check {
					color: $su-color-cardinal-red;
				}

				.fa-calendar-alt {
					color: $su-color-digital-red;
				}

				.fa-users {
					color: $su-color-lagunita;
				}

				.fa-building {
					color: $su-color-cool-grey;
				}

				.fa-map-marker-alt {
					color: $su-color-poppy;
				}
			}
		}
	}

	// All <li>s of parent <ul>.
  li {
		@include margin(null null 0);

		a {
			display: block;
			color: $su-color-black;
			font-weight: $su-font-regular;
			font-size: 1.5rem;
			border-bottom: $su-color-cool-grey solid 1px;
			outline: 0;
		}

		// Nested <ul> and .datepicker are hidden, revealed with js.
		> ul,
		> div.datepicker {
	    display: none;
		}

		&.su-main-nav__item--expanded {
			> ul,
			> div.datepicker {
				display: flex;
				flex-direction: column;
				box-shadow: rgba(0, 0, 0, 0.5) 0 0 8px;
			}
		}

		&.su-main-nav__item--current > a {
	    font-weight: $su-font-bold;
	    color: $su-color-dark-red;

	    &::before {
				visibility: visible;
				transform: scaleY(1);
				background-color: $su-color-dark-red;
				transition: left 0.1s ease-out;
	    }

	    &:hover,
	    &:focus {
				&::before {
		    	left: 6px;
				}
	    }
		}

		&.su-main-nav__item--parent {

			> a::after {
				content: "";
				display: inline-block;
				@include margin(null null 1px 6px);
				background: url("#{$su-image-path}/caret-down-black.svg") no-repeat 0 0;
				background-size: 100%;
				position: relative;
				right: 0;
				top: 0;
				height: 11px;
				width: 11px;
				transition: transform 0.3s ease-out;
	    }

	    &.su-main-nav__item--expanded > a {
				background-color: $su-color-black;
				border: none;
				color: $su-color-white;

				i {
					color: $su-color-white !important;
				}

				&::after {
					background: url("#{$su-image-path}/caret-down-white.svg") no-repeat 0 0;
					background-size: 100%;
					transform: rotate(180deg);
				}
	  	}
		}

			@include grid-media-max('md') {

				&.su-main-nav__item--current {
					&.su-main-nav__item--expanded {

					> a {
						font-weight: $su-font-bold;

						&::before {
							background-color: $su-color-white;
						}
					}
				}
			}
		}
  }

  // When js is disabled, submenus is visible when parent trigger is hovered
	&.no-js {
		//Expand everything on mobile
		@include grid-media-max('sm') {

			.su-main-nav__toggle[aria-expanded="false"] + .su-main-nav__menu-lv1 {
				display: flex;
			}

			li > ul {
				display: flex;
			}
		}

		//On desktop, if mouse user hovers over a parent menu item, submenu is expanded
		@include grid-media('sm') {
			li:hover > ul {
				display: flex;
			}
		}
  }
}

.su-main-nav__toggle {

  @include grid-media-max('sm') {
		@include padding(0 0 2rem);
		@include margin(0);
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		width: 40px;
		background-color: transparent;
		color: $su-color-black;
		font-size: 1.6rem;
		font-weight: $su-font-regular;
		line-height: 0.7;

		&::before {
			height: 0;
		}

		&::after {
			@include margin(0 auto);
			display: inline-block;
			width: 30px;
			height: 26px;
			background: url("#{$su-image-path}/hamburger-black.svg") no-repeat 3px 0;
			content: "";
		}

		&[aria-expanded="true"] {
			&::before {
				visibility: visible;
				transform: scaleX(1);
				background-color: $su-color-driftwood;
			}

			&::after {
				width: 22px;
				background: url("#{$su-image-path}/close-black.svg") no-repeat 3px 0;
				background-size: 16px 16px;
			}

			&:hover::before {
				background-color: $su-color-black;
			}

			&:active::before {
				background-color: $su-color-black;
			}
		}

		&:hover,
		&:active,
		&:focus {
			background-color: transparent;
			color: $su-color-black;
			box-shadow: none;
		}

		&[aria-expanded="false"] ~ .su-main-nav__menu-lv1 {
			display: none;
		}

		//toggle button variants
		&--center {
			@include margin(null auto);
		}

		&--right {
			@include margin(null 0 null auto);
		}
  }

  @include grid-media-only('md') {
		&[aria-expanded="true"]  + .su-main-nav__menu-lv1 {
			max-width: 24em;
		}
  }

  @include grid-media('md') {
		display: none;
  }
}

.su-main-nav__menu-lv1 > li {

  @include grid-media('sm') {

		> a {
		color: $su-color-black;
		transition: color 0.3s ease-out;

	    &:hover,
	    &:focus {
				color: $su-color-black;
	    }

			&:active,
			&[aria-expanded="true"] {
				color: $su-color-black;
			}

			&[aria-expanded="true"]:hover {
				color: $su-color-white;
			}
		}
  }

  &.su-main-nav__item--current {

		> a {
			&::before {
				transform: scaleY(1);
				background-color: $su-color-white;
			}

			&:hover,
			&:focus {

				&::before {
					background-color: $su-color-white;
				}
			}
		}

		@include grid-media-max('md') {

			&.su-main-nav__item--expanded {
				> a:focus::before {
					left: 0;
					background-color: $su-color-black;
				}

				> a[aria-expanded="true"] {

					&:hover::before {
						left: 6px;
					}

					&:active {
					font-weight: $su-font-bold;

						&::before {
							background-color: $su-color-vivid-red;
						}
					}
				}
			}
		}

		@include grid-media('lg') {

			> a {

				&::before {
					background-color: $su-color-black;
				}

				&:hover,
				&:focus {
					&::before {
						left: 0;
						background-color: $su-color-dark-red;
					}
				}
			}
		}
	}
}

.su-main-nav__menu-lv2 {
	@include padding(1px null null 1rem);
	@include margin(0 null null 0);
	@include box-shadow($depth: 'medium', $stroke: none);
	list-style: none;
	background-color: $su-color-white;
	border-top: 5px solid $su-color-black !important;
	z-index: 11111;
	position: absolute;
	max-width: 30rem;

  li:last-child {

		a {
				border-bottom: 0;
		}
  }

  a {
		@include fancy-hover($color: $su-color-white, $active: $su-color-vivid-red, $position: 'left');
  }

  a.su-main-nav__menu-lv3 {
		@include padding(0 0 1rem 3rem);
		border-bottom: none;
  }

  @include grid-media-max('sm') {
		width: 100%;
		max-width: 100%;
		left: 0;
  }
}

.su-card--horizontal {
  display: grid;

  > img {
		align-self: start; // IE fix no stretching
  }
}

@include grid-media-max('sm') {

	.su-main-nav__toggle {
		@include padding(0);
	}

  .su-main-nav > ul {
		@include padding(1.2em null null);
  }
}

@include grid-media-max('md') {

  #nav-organization a > span {
		display: none;
  }
}

@include grid-media-only('md') {

  .su-main-nav__toggle[aria-expanded="true"] + .su-main-nav__menu-lv1 {
		max-width: 100%;
  }
}
