// This file contains styles specifically for the public event
// submission form – /event/create. The template for this in the
// Theme Editor has fallback fields for when JS is disabled, but
// it appears that otherwise the form is created with React. We
// do not have access to the React code to make any modifications
// so we're using Localist classes and overriding for the display
// we need to achieve.

@mixin label-float {
  font-size: 70%;
  transform: translate3d(0, -80%, 0);
}

.su-public-event-form {
  fieldset {
    padding-left: 0;
    padding-right: 0;
  }

  label {
    margin: 0;
  }

  input {
    margin: 0;
    max-width: none;
  }

  .preview {
    @include modular-typography(-1);
  }

  .required {
    font-size: 1.2rem;
  }
}

.em-input-floating {

  &:focus-within {
    .em-input-floating--label {
      @include label-float;
    }
  }

  input,
  textarea {
    border-radius: 0;
    font-size: inherit;

    &:not(:placeholder-shown) {
      border: 1px solid $su-color-black;
      max-width: none;
      width: 100%;
    }
  }

  .em-input-floating--label {
    @include label-float;
    margin: 0;
  }
}

.em-input-floating--placeholder {

  .em-input-floating--label {
    @include modular-typography-base;
    margin: 0;
    transform: translateY(-15%);
  }

  &:focus-within {

    .em-input-floating--label {
      @include label-float;
    }
  }
}

.em-input-floating--focused {

  .em-input-floating--label {
    @include label-float;
  }
}

.em-input-floating--input {

  &:focus {
    border-color: $su-color-digital-blue;
    box-shadow: 0 0 0 1px $su-color-digital-blue;
  }
}

// CK Editor textarea styles.
.em-input_textarea {

  .editable {
    background: $su-color-white;
  }

  .cke_editable {

    p {
      font-size: inherit;
    }
  }
}

.editor_toolbar {
  border: 1px solid $su-color-black;
  border-bottom: none;
  border-radius: 0;

  +.editable {

    .cke_editable {
      border: 1px solid $su-color-black;
      border-radius: 0;
      min-height: 20rem;
    }
  }
}

// Floating label select list styles
.em-select-floating {
  border: 0;
	border-radius: 0;

  select {
    background-color: $su-color-white;
    border: 1px solid $su-color-black;
    border-radius: 0;
		font-size: inherit;
		margin: 0;
		max-width: none;

    &:focus {
			border-color: $su-color-digital-blue;
			box-shadow: 0 0 0 1px $su-color-digital-blue;
		}
  }

  label {
    @include modular-typography-base;
    margin: 0;
    top: 1rem;

    // There are some select fields where the selected value AND label are
    // always displayed.These styles place the labe where it would otherwise
    // be floated.
    &.su-event_status--sm {
      @include label-float;
      top: 1.45rem;
    }
  }
}

// Needed to target the "Repeating" field in the Schedule fieldset.
// This is injected with React, so not able to added the class above
// for scaling the label. The id below was the only way to target.
#x-instance-management {
  .em-select-floating {

    label {
      @include label-float;
      top: 1.45rem;
    }

    .fa-sort-down {
      display: none;
    }
  }

  // Style for the "Confirmed Dates" heading.
  // Needed the parent id #x-instance-management for specificity.
  .em-input_label {
    font-weight: $su-font-bold;
  }
}

// Photo uploader field.
.photo_upload_entry {

  #photo-chooser {
    background-color: $su-color-white;
    border: 1px solid $su-color-black;
    margin-bottom: 3rem;
  }

  .drop-target-text {

    a {

      &:first-of-type {

        &::after {
          content: ' | ';
        }
      }
    }
  }
}

// When Filters are selected, the selections appear below the filed.
// The following styles are for those.
.em-add_event {

  .picked_item_list {

    .picked_item {
      @include modular-typography(-1);

      &.em-picked_item {
        background-color: $su-color-white;

        .fas.fa-times {
          @include modular-typography(-1);
        }
      }
    }
  }
}

// Within the Schedule fieldset, you can add multiple dates.
// Each event is added below the "Confirmed Dates" heading.
// Below are styles for those listings.
.em-add_event {

  .instance_list {

    .event_item {
      background-color: $su-color-white;

      p {
        margin: 0;

        .deletex {

          a {
            @include modular-typography(-1);
          }
        }
      }
    }
  }
}

.em-input_container {
  margin-bottom: 3rem;
}

// When entering a value in the "Place" field it uses the location
// info within Localist and adds predictive text to help users
// find existing locations. The font-size already has an !important
// on it so we need to add that here to overwrite.
ul.ui-autocomplete {
  padding: 0;

  li a {
    font-size: .8em !important;
    padding: 1rem;

    &.ui-state-active,
    &.ui-state-hover,
    &.ui-state-focus {

      .location {
        color: $su-color-white;
      }
    }
  }

  li div.location {
    font-size: .7em;
    margin-top: .7em;
  }
}

// When an existing place is added, it displays with an X to remove.
// It also overlays the field that was there. 
.em-add_event {
  .em-place_result {
    background-color: $su-color-foggy-light;
  }
}
