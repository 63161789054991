// Localist filters.
.em-filter-wrapper {
	max-width: none;

	// margin-top only needed on homepage
	.su-homepage & {
		margin-top: 3.75rem;
	}

	// Filter header.
	h1.em-filter-header,
	h2.em-filter-header {
		margin: 0;
		padding: 0;
	}

	.em-filter {
		align-self: flex-end;

		#reportrange {
			@include modular-typography-base;
			background: url("#{$su-image-path}/arrow-down.png");
			background-image: url("#{$su-image-path}/arrow-down.svg");
			background-position: right 1.3rem center;
			background-repeat: no-repeat;
			background-size: 1.3rem;
			height: 4.8rem;
			line-height: 1.5;
		}
	}

	.em-buttons-item {

		.em-buttons-link {
			border-radius: 0;
			height: 4rem;
			width: 4rem;
		}
	}

	// Filter dropdown/tray.
	.em-filter-dropdown {
		z-index: 5;

		@include grid-media('md') {
			max-width: 46rem;
			width: 46rem;
			top: 6rem;
		}

		.em-filter-dropdown-header {
			grid-template-columns: 1fr auto;
			justify-items: start;

			label {
				margin: 0;
			}
		}

		.em-filter-dropdown-extra-options {

			label {
				margin: 0;

				input {
					border: 0;

					~ i {
						font-size: 2.2rem;
					}
				}

				span {
					font-size: 1.6rem;
				}
			}

			.em-option-select-layout {
				grid-template-columns: repeat(3, 3rem);
				grid-template-rows: 3rem;
				grid-column-gap: 1rem;

				label {
					margin: 0;

					input {

						~ i {
							font-size: 2.2rem;
						}
					}
				}
			}
		}
	}
}

// .em-more-options panel styles
// This dropdown/tray is shown by clicking the three dots button next to the
// filter button on the listing pages.

// Headings for each section of the More Options panel.
.em-filter-dropdown {

	 h2,
	 .em-content_label {
		font-size: 1.3rem;
		letter-spacing: 0;
		text-transform: none;

		#event_results & {
			font-size: inherit;
		}
	}
}

// Other Calendars (second) section of more options dropdown/tray.
.em-channel-list-components {
	// m/p from Decanter _list.scss
	ul {
		margin: 0;
		padding: 0;
	}
}

// Explore (top) section of more options dropdown/tray.
.em-platform-nav-component {
	border-radius: 0;

	.em-platform-nav-container {

		a.em-button {

			i {
				line-height: 1.3;
			}
		}
	}
}

.em-default-list-component {

	fieldset {
		padding: 0;
	}
}
