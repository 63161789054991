// General layout-related styles.

// Localist has flex on the body element.
// Flex messes things up though and isn't necessary.
body {
  display: block;
}

// .content-wrapper is a top-level selector that wraps the page content.
// Localist has the max-width set to 60rem, so we're overriding for our 10px base.
.content-wrapper {
  // @include flex-column($columns: 8);
  // @include flex-grid-push($size: 2, $columns: 12);
  // @include centered-column;
  max-width: 100rem;
}

.em-add_event {
  margin: 5rem auto;
  max-width: 62rem;
}

// Login form at /auth/login.
// Also login modal, which displays when clicking the Log in button in the user menu.
.em-login-view {
  max-width: 32rem;

  .em-top-text {

    .em-default-header {
      font-size: 3rem;
      line-height: 1.2;
    }
  }

  .em-signup-form {

    .em-email-signup {

      .em-button {
        max-width: none;
      }

      .em-separator {

        .em-or-separator {
          font-size: 1.3rem;
          margin-bottom: 0;
        }
      }

      .em-login-form {

        .em-basic-input {
          width: 100%;
        }
      }
    }
  }

  .em-login-bottom-text {

    a {
      @include modular-typography-base;
    }
  }
}

// Creat account page at /signup
.em-signup-container {
  max-width: 32rem;

  .em-signup-header {
    font-size: 3rem;
  }

  .em-signup-explanation {
    @include modular-typography-base;
  }

  .em-signup-form {
    width: 100%;

    .em-basic-input {

      input {
        width: 100%;
      }
    }

    .em-signup-captcha {
      width: auto;

      #recaptcha {

        .g-recaptcha {

          > div {
            margin: auto;
          }
        }
      }

      iframe {
        max-height: 8rem;
      }
    }

    .em-button {
      max-width: none;
      width: 100%;
    }
  }

  .em-signup-separator {

    .em-or-separator {
      font-size: 1.3rem;
      margin-bottom: 0;
    }
  }

  .em-login_methods {
    width: 100%;

    .em-button.em-primary {
        width: 100%;
    }
  }

  .em-signup-bottom-text {

    p {
      margin: 0;
    }

    a {
      font-size: inherit;
    }
  }
}

// Layout classes for when we need 2-column layout.
// Wide left content column + right sidebar. These styles
// require that the left column be an <article> and the sidebar
// be an <aside>. If this becomes problematic we can change to
// use selectors instead of elements. Right now this is only
// used for 2 channel pages – Stanford For You form and archive.
// Copied from trunks-right example in Decanter.

$_layout-trunks-columns: (
  $su-breakpoint-xs: 1fr,
  $su-breakpoint-sm: 1fr 1fr 1fr,
  $su-breakpoint-md: 1fr 1fr 1fr,
  $su-breakpoint-lg: 1fr 1fr 1fr,
  $su-breakpoint-xl: 1fr 1fr 1fr,
  $su-breakpoint-2xl: 1fr 1fr 1fr
);

.su-2-col--right-sidebar {
  @include responsive-grid-columns($_layout-trunks-columns);

  aside {
    @include grid-media('sm') {
      @include grid-item-spans(3, span 1);
    }
  }

  article {
    @include grid-media('sm') {
      @include grid-item-spans(1, span 2);
    }
  }
}
